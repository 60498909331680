import React, { FC, Fragment, useContext, useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import './MyWorkflowComponent.css';
import MyWorkflowStore from '../../../stores/MyWorkflowStore';
import MyWorkflowService from '../../../services/MyWorkflowService';
import IWorkflow from '../../../entities/Workflow';
import IWorkflowInstance from '../../../entities/WorkflowInstance';
import { useParams, Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Row, Alert, Col, Button, Card, CardHeader, CardBody, CardFooter, FormGroup, Input, Label, Table } from 'reactstrap';
import { v4 } from 'uuid';
import IWorkflowStep from '../../../entities/WorkflowStep';
import IWorkflowStepAction from '../../../entities/WorkflowStepAction';
import IWorkflowInstanceStateAction from '../../../entities/WorkflowInstanceStateAction';
import AuthStore from '../../../stores/AuthStore';
import AuthService from '../../../services/AuthService';
import IUser from '../../../entities/User';
import WorkflowStore from '../../../stores/WorkflowStore';
import Select from 'react-dropdown-select';
import WorkflowInstanceComment from '../../../entities/WorkflowInstanceComment';
import IWorkflowInstanceComment from '../../../entities/WorkflowInstanceComment';
import * as _ from 'lodash';

const MyWorkflowComponent : FC = () => {

    const myworkflowsStore = useContext(MyWorkflowStore);
    const myworkflowsService = new MyWorkflowService();
    const authStore = useContext(AuthStore);
    const authService = new AuthService();
    const { id } = useParams<{id: string}>();
    const [newworkflow, setnewworkflow] = useState<IWorkflowInstance | undefined>(undefined);
    const [successmessage, setsuccessmessage] = useState('');
    const [errormessage, seterrormessage] = useState('');
    const [availableUsers, setAvailableUsers] = useState<IUser[]>([]);
    const [workflow, setWorkflow] = useState<IWorkflow | undefined>(undefined);
    const [currentviewing, setcurrentviewing] = useState(0);
    const [comment, setcomment] = useState('');
    const [currentWorkflow, setCurrentWorkflow] = useState<IWorkflowInstance | undefined>(undefined);
    const [newComment, setNewComment] = useState<WorkflowInstanceComment>({
        id: "",
        approved: false,
        instanceId: id,
        text: ""
    });
    const history = useHistory();
    const [instanceActions, setinstanceActions] = useState<Map<string, IWorkflowInstanceStateAction>>(new Map<string, IWorkflowInstanceStateAction>());

    useEffect(() => {
        myworkflowsStore.setCurrentWorkflow('');
        myworkflowsService.getWorkflows().then((result: Array<IWorkflow>) => {
            myworkflowsStore.setWorkflows(result);
        });
        myworkflowsService.getWorkflow(id).then((result: IWorkflowInstance) => {
            setCurrentWorkflow(result);
        })
        // myworkflowsService.getMyWorkflows().then((result: Array<IWorkflowInstance>) => {
        //     myworkflowsStore.setMyWorkflows(result);
        //     if(id){
        //         myworkflowsStore.setCurrentWorkflow(id);
        //     }
        // });
        fetchUsers()
    }, [MyWorkflowStore, id]);

    const startWorkflow = () => {
        if(workflow){
            const newid: string = v4();
            myworkflowsService.startWorkflow(
                {
                    id: newid, 
                    workflowId: workflow?.id, 
                    startedAt: new Date(Date.now())
                }).then(res => {
                    history.push('/myworkflows/' + newid);
                });
        }
    };

    const selectworkflow = (e: ChangeEvent<HTMLInputElement>) => {
        setWorkflow(myworkflowsStore.workflows.get(e.currentTarget.value));
    };

    const changeFileInput = (e: FormEvent<HTMLInputElement>, actionId: string) => {
        let files = e.currentTarget.files;
        let wi = {...currentWorkflow} as IWorkflowInstance;
        let existingdata = currentWorkflow?.actions.filter((a) => a.actionId === actionId)[0];
        var exfiles = existingdata?.data;
        console.log(exfiles);
        if(files !== null){
            if(currentWorkflow?.actions
            .filter((a) => a.actionId === actionId).length === 0){
            
                myworkflowsService.uploadFile(files[0]).then(res => {
                    let myactions = instanceActions;
                    let newactionid = v4();
                    let newAction: IWorkflowInstanceStateAction = {
                        actionId,
                        data: exfiles ? exfiles.concat(res) : res,
                        id: newactionid,
                        instanceId: id,
                        status: wi?.status ? wi.status : 0,
                        user: authStore.user
                    };
                    myworkflowsService.createAction(newAction).then(res => {
                        
                        wi?.actions.push(newAction);
                        setCurrentWorkflow(wi as IWorkflowInstance);
                        console.log(wi);
                    }).catch(e => {
                        console.log(e);
                    });
                });
            }
            else{
                myworkflowsService.uploadFile(files[0]).then(res => {
                    let myactions = instanceActions;
                    let newactionid = v4();
                    let newAction =  currentWorkflow?.actions
                    .filter((a) => a.actionId === actionId)[0] as IWorkflowInstanceStateAction;
                    newAction.data = exfiles ? JSON.stringify(JSON.parse(exfiles).concat(JSON.parse(res))) : res;
                    myworkflowsService.editAction(newAction).then(res => {
                        for(let i = 0; i < (wi?.actions.length ? wi?.actions.length : 0); i ++){
                            if(wi?.actions[i].id === newAction.id){
                                wi.actions[i] = newAction;
                            }
                        }
                        setCurrentWorkflow(wi as IWorkflowInstance);
                        console.log(wi);
                    }).catch(e => {
                        console.log(e);
                    });
                });
            }
        }
    };

    const changeTextInput = _.debounce((e: string, actionId: string) => {
        let wi = {...currentWorkflow} as IWorkflowInstance;
        if(wi?.actions.filter((a: IWorkflowInstanceStateAction) => a.actionId === actionId).length === 0){
            const newactionid = v4();
            let newAction: IWorkflowInstanceStateAction = {
                actionId,
                data: e,
                id: newactionid,
                instanceId: id,
                status: wi?.status ? wi.status : 0,
                user: authStore.user
            }
            console.log(newAction);
            myworkflowsService.createAction(newAction).then(res => {
                wi?.actions.push(newAction);
                setCurrentWorkflow(wi as IWorkflowInstance);
            }).catch(error => {
                console.log(error);
            });
        }
        else{
            let myactions = instanceActions;
            let newactionid = v4();
            let newAction =  currentWorkflow?.actions
            .filter((a) => a.actionId === actionId)[0] as IWorkflowInstanceStateAction;
            newAction.data = e;
            myworkflowsService.editAction(newAction).then(res => {
                let wi = currentWorkflow;
                for(let i = 0; i < (wi?.actions.length ? wi?.actions.length : 0); i ++){
                    if(wi?.actions[i].id === newAction.id){
                        wi.actions[i] = newAction;
                    }
                }
                setCurrentWorkflow(wi as IWorkflowInstance);
                //console.log(wi);
            }).catch(e => {
                console.log(e);
            });
        }
    }, 500);

    const changeSelectInput = _.debounce((v: string, actionId: string) => {
        let wi = {...currentWorkflow} as IWorkflowInstance;
        if(wi?.actions.filter((a: IWorkflowInstanceStateAction) => a.actionId === actionId).length === 0){
            const newactionid = v4();
            let newAction: IWorkflowInstanceStateAction = {
                actionId,
                data: v,
                id: newactionid,
                instanceId: id,
                status: wi?.status ? wi.status : 0,
                user: authStore.user
            }
            myworkflowsService.createAction(newAction).then(res => {
                wi?.actions.push(newAction);
                setCurrentWorkflow(wi as IWorkflowInstance);
            }).catch(error => {
                console.log(error);
            });
        }
        else{
            let myactions = instanceActions;
            let newactionid = v4();
            let newAction =  currentWorkflow?.actions
            .filter((a) => a.actionId === actionId)[0] as IWorkflowInstanceStateAction;
            newAction.data = v;
            myworkflowsService.editAction(newAction).then(res => {
                let wi = currentWorkflow;
                for(let i = 0; i < (wi?.actions.length ? wi?.actions.length : 0); i ++){
                    if(wi?.actions[i].id === newAction.id){
                        wi.actions[i] = newAction;
                    }
                }
                setCurrentWorkflow(wi as IWorkflowInstance);
                //console.log(wi);
            }).catch(e => {
                console.log(e);
            });
        }
    }, 500);

    const nextStep = () => {
	if(window.confirm('θέλετε σίγουρα να προχωρήσετε;')){
        if(currentWorkflow?.workflow && currentWorkflow.actions){
            let actionstaken = currentWorkflow.actions ? currentWorkflow.actions : [];
            let unanswered = [];
            for(let i = 0; i < currentWorkflow.workflow.steps.length; i ++){
                let step = currentWorkflow.workflow.steps[i];
                if(step.status === currentWorkflow.status){
                    unanswered = step.actions.filter((a: IWorkflowStepAction) => {
                        for(let j = 0; j < actionstaken.length; j ++){
                            if(actionstaken[j].actionId === a.id){
                                return false;
                            }
                        }
                        if(a.type === 'mechanicAction' || a.type === "checkFields"){
                            return false
                        }
                        else{
                            if(a.required){
                                return true;
                            }
                            else return false;
                        }
                        
                    });
                }
            }
            if(unanswered.length === 0){
                let wi = currentWorkflow as IWorkflowInstance;
                if(wi.actions.filter((a: IWorkflowInstanceStateAction) => a.status > wi.status).length === 0){
                    wi.status ++;
                }
                else{
                    if(wi.status === 1){
                        wi.status = wi.actions.sort((a: IWorkflowInstanceStateAction, b: IWorkflowInstanceStateAction) => a.status<b.status ? 1 : -1)[0].status;
                    }
                    else{
                        wi.status ++;
                    }
                }
                myworkflowsService.nextStep(id, wi.status).then(res => {
                    
                    setCurrentWorkflow(wi);
                    setsuccessmessage(wi.status === 2 ? 'Η πρότασή σας καταχωρήθηκε επιτυχώς' : 'Η ενέργεια ολοκληρώθηκε επιτυχώς');
                    setTimeout(() => {
                        setsuccessmessage('');
                    }, 3000);
                });
            }
            else{
                seterrormessage('Πρέπει να συμπληρώσετε όλα τα πεδία');
                setTimeout(() => {
                    seterrormessage('');
                }, 3000);
            }
        }
	}
    };

    const rejectInstance = () => {
        if(window.confirm('Are you sure you want to reject the proposition?')){
            let wi = currentWorkflow as IWorkflowInstance;
            myworkflowsService.rejectInstance(wi.id).then(res => {
                wi.paused = true;
                setCurrentWorkflow(wi);
                history.push('/myworkflows');
            });
        }
    };

    const sendBackInstance = (status: number) => {
        if(window.confirm('Are you sure you want to send the proposal back for correction?')){
            let wi = currentWorkflow as IWorkflowInstance;
            if(comment){
                let ccs = wi.comments ? JSON.parse(wi.comments) : [];
                ccs.push({
                    Dt: new Date(Date.now()),
                    UserName: authStore.user?.displayName,
                    Comment: comment
                });
                wi.comments = JSON.stringify(ccs);
            }
            myworkflowsService.sendBackInstance(wi.id, status, comment).then(res => {
                wi.status = status;
                setCurrentWorkflow(wi);
            });
        }
    };

    const sendBackToManagerInstance = () => {
        if(window.confirm('Are you sure you want to send the proposal back to the manager?')){
            let wi = currentWorkflow as IWorkflowInstance;
            myworkflowsService.sendBackInstance(wi.id, wi.status-1).then(res => {
                wi.status = wi.status-1;
                setCurrentWorkflow(wi);
            });
        }
    };

    const fetchUsers = () => {
        authService.getMechanics().then((res: IUser[]) => {
            setAvailableUsers(res);
        })
    };

    const kanetomalaka = () => {
        setsuccessmessage('Οι αλλαγές σας αποθηκεύθηκαν επιτυχώς');
        setTimeout(() => {
            setsuccessmessage('');
        }, 3000);
    }

    const changeComment = (e: FormEvent<HTMLInputElement>) => {
        setcomment(e.currentTarget.value);
        console.log(comment);
    };

    const approveSendback = () => {
        if(currentWorkflow){
            myworkflowsService.approveSendback(currentWorkflow.id).then((result: boolean) => {
                if(result){
                    setsuccessmessage('Η αιτηση επεστράφη στο δήμο')
                    setTimeout(() => {
                        setsuccessmessage('');
                        let wf = currentWorkflow;
                        if(wf){
                            wf.needsManagerApproval = false;
                            setCurrentWorkflow(wf);
                        }
                    }, 3000);
                }
                else{
                    seterrormessage('Η διαδικασία απέτυχε');
                    setTimeout(() => {
                        seterrormessage('');
                    }, 3000)
                }
            })
        }
    };

    const declineSendback = () => {
        if(currentWorkflow){
            myworkflowsService.approveSendback(currentWorkflow.id).then((result: boolean) => {
                if(result){
                    setsuccessmessage('Η αιτηση επεστράφη στον αξιολογητή και δεν επέστρεψε στο δήμο');
                    setTimeout(() => {
                        setsuccessmessage('');
                        let wf = currentWorkflow;
                        if(wf){
                            wf.needsManagerApproval = false;
                            wf.status = wf.actions.reduce((s: number, a: IWorkflowInstanceStateAction) => s = a.status > s ? a.status : s, wf.status);
                            setCurrentWorkflow(wf);
                        }
                    }, 3000);
                }
                else{
                    seterrormessage('Η διαδικασία απέτυχε');
                    setTimeout(() => {
                        seterrormessage('');
                    }, 3000)
                }
            })
        }
    };

    const saveComment = async (aproved: boolean) => {
        let nc = {
            ...newComment,
            instanceId: id,
            approved: aproved
        } as IWorkflowInstanceComment;

        setNewComment(nc);
        await myworkflowsService.saveComment(nc);
        setsuccessmessage('Η αξιολόγησή σας έχει αποθηκευτεί');
    }

    const deleteFile = (a: IWorkflowInstanceStateAction, index: number) => {
        
        let wi = {...currentWorkflow} as IWorkflowInstance;
        let files: any[] = JSON.parse(a.data);
        files.splice(index, 1);
        a.data = JSON.stringify(files);
        myworkflowsService.editAction(a).then(res => {
            for(let i = 0; i < (wi?.actions.length ? wi?.actions.length : 0); i ++){
                if(wi?.actions[i].id === a.id){
                    wi.actions[i] = a;
                }
            }
            setCurrentWorkflow(wi as IWorkflowInstance);
            console.log(wi);
        }).catch(e => {
            console.log(e);
        });
    };

    return (
        <Fragment>
            { id && 
            <Row>
                <Col md="12">
                    <Link to="/myworkflows" className="btn btn-warning float-right" >Πίσω στις προτάσεις</Link> 
                    <Button color="success" className="float-right" style={{marginRight: '15px'}} onClick={kanetomalaka}>Προσωρινή αποθήκευση</Button>
                </Col>
            </Row>
            }
            <hr/>
            <Row>
                {
                    !id && <Col md="12">
                        <Card>
                            <CardHeader className="bg-info">
                                <h3>Δημιουργία νέας πρότασης</h3>
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <Input type="select" onChange={selectworkflow}>
                                        <option value="">Επιλέξτε Πρόγραμμα</option>
                                        {
                                            Array.from(myworkflowsStore.workflows.values()).filter((w) => {
                                                if(!w.beneficiaries || (w.beneficiaries && w.beneficiaries.split(',').includes(authStore.user?.email as string))){
                                                    return true;
                                                }
                                                else{
                                                    return false;
                                                }
                                            }).map((w: IWorkflow) => {
                                                return <option key={w.id} value={w.id}>{w.name}</option>
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                                    <p>{workflow?.description}</p>
                            </CardBody>
                            <CardFooter>
                                {
                                    workflow && <Button onClick={startWorkflow} color="primary" className="float-right">Έναρξη</Button>
                                }
                            </CardFooter>
                        </Card>
                    </Col>
                }
                {
                    id && currentWorkflow?.workflow && (
                        <Col md="12">
                            <h1 className="text-center">{currentWorkflow.workflow.name}</h1>
                        </Col>
                    )
                }
                {
                    currentWorkflow && (currentWorkflow.completed || (currentWorkflow?.status > (currentWorkflow?.workflow as IWorkflow).steps.length)) && (
                        <Col md="12">
                            <h1 className="text-center text-success">Η πρόταση Εγκρίθηκε</h1>
                        </Col>
                    )
                }
                {
                    currentWorkflow?.paused && (
                        <Col md="12">
                            <h1 className="text-center text-danger">Η πρόταση απορρίφθηκε</h1>
                        </Col>
                    )
                }
                {
                    successmessage && <Alert color="success" style={{position: 'fixed', top: '50px', left: '75%', zIndex: 999}}>
                        {successmessage}
                    </Alert>
                }
                {
                    errormessage && <Alert color="danger" style={{position: 'fixed', top: '50px', left: '75%', zIndex: 999}}>
                        {errormessage}
                    </Alert>
                }
                {
                    currentWorkflow?.needsManagerApproval &&
                    <Card>
                        <CardHeader className="bg-primary">
                            <p className="text-center">Σε αναμονή έγκρισης από τον Διευθυντή για επιστροφή στον Δήμο</p>
                        </CardHeader>
                        <CardBody>
                            {
                                (authStore.user?.roles.includes('Administrator') || authStore.user?.roles.includes('Manager')) &&
                                <Fragment>
                                    <Button color="success" onClick={approveSendback}>Επιστροφή στο Δήμο</Button>
                                    <Button color="danger" onClick={declineSendback}>Επαναπροώθηση στον Αξιολογητή</Button>
                                </Fragment>
                            }
                            {
                                <p>Ο αξιολογητής έχει στείλει την αίτηση στο δήμο για επανεξέταση. Αναμονή έγκρισης της κίνησης από τον Διευθυντή</p>
                            }
                        </CardBody>
                    </Card>
                }
                {
                    currentWorkflow?.workflow.steps
                    .sort((a: IWorkflowStep, b: IWorkflowStep) => {
                        return a.status > b.status ? 1 : -1;
                    })
                    .map((step: IWorkflowStep) => {
                        return (
                            <Col md="12" key={step.id}>
                                <Card>
                                    <CardHeader onClick={() => { setcurrentviewing(step.status); console.log(currentviewing);console.log(step.status) }} className={ "clickable-header " + (step.status === currentWorkflow?.status ? 'bg-info' : 'bg-light') }>
                                        <h4>{step.name} {step.status === 1 && currentWorkflow?.status
                                        && currentWorkflow.status > 1 && <span className="text-success">Η πρότασή σας έχει υποβληθεί</span> }
                                        {
                                            step.status < (myworkflowsStore?.currentWorkflow ? myworkflowsStore?.currentWorkflow.status : 0) &&
                                            step.actions.length === 1 && step.actions[0].type === 'completeField' && 
                                            <span className="text-success">{myworkflowsStore?.currentWorkflow?.actions
                                                .filter((a: IWorkflowInstanceStateAction) => {
                                                    return a.status === step.status
                                                })[0].data}</span>
                                        }
                                        {
                                            step.status < (myworkflowsStore?.currentWorkflow ? myworkflowsStore?.currentWorkflow.status : 0) &&
                                            step.actions.length === 1 && step.actions[0].type === 'assignToMechanic' && myworkflowsStore?.currentWorkflow?.isAssigned && 
                                        <span className="text-success"> Ανατέθηκε στον {currentWorkflow?.userAssigned && currentWorkflow?.userAssigned.displayName}</span>
                                        }
                                        {
                                            step.status < (myworkflowsStore?.currentWorkflow ? myworkflowsStore?.currentWorkflow.status : 0) &&
                                            step.actions.length === 1 && step.actions[0].type === 'mechanicActions' && 
                                            <span className="text-success"> 
                                            {(currentWorkflow?.status ? currentWorkflow?.status : 0) > step.status ? 'Ο αξιολογητής Ανέλαβε την πρόταση' : 'Ο μηχανικός απέρριψε την πρόταση'}
                                            </span>
                                        }
                                        {
                                            step.status < (myworkflowsStore?.currentWorkflow ? myworkflowsStore?.currentWorkflow.status : 0) &&
                                            step.actions.length === 1 && step.actions[0].type === 'checkFields' && 
                                            <span className="text-success"> 
                                            {
                                                currentWorkflow?.paused === true ? 'Ο αξιολογητής απέρριψε την πρόταση' : ''
                                            }
                                            {(currentWorkflow?.status ? currentWorkflow?.status : 0) > step.status ? 'Ο αξιολογητής ενέκρινε τα έγγραφα που υπεβλήθησαν' : 'Ο μηχανικόε επέστρεψε τα έγγραφα στο δήμο προς επανέλεγχο'}
                                            </span>
                                        }
                                        {
                                            step.status < (myworkflowsStore?.currentWorkflow ? myworkflowsStore?.currentWorkflow.status : 0) &&
                                            step.actions.length === 1 && step.actions[0].type === 'uploadFile' && JSON.parse(myworkflowsStore?.currentWorkflow?.actions
                                                .filter((a: IWorkflowInstanceStateAction) => {
                                                    return a.status === step.status
                                                })[0].data as string)[0]?.name &&
                                            <span className="text-success">
                                                <a href={"https://filemanager.intelliflow.gr/uploads/" + JSON.parse(myworkflowsStore?.currentWorkflow?.actions
                                                .filter((a: IWorkflowInstanceStateAction) => {
                                                    return a.status === step.status
                                                })[0].data as string)[0].name}>{
                                                    JSON.parse(myworkflowsStore?.currentWorkflow?.actions
                                                        .filter((a: IWorkflowInstanceStateAction) => {
                                                            return a.status === step.status
                                                        })[0].data as string)[0].name}
                                                </a>
                                            </span>
                                        }
                                        </h4>
                                    </CardHeader>
                                    {
                                        step.status === currentWorkflow?.status && !currentWorkflow?.completed && !currentWorkflow?.paused &&
                                        (authStore.user?.roles.includes(step.role) || authStore.user?.roles.includes("Administrator") || step.role === 'Mechanic' && authStore.user?.roles.includes("Manager")) &&  (
                                            <Fragment>
                                                <CardBody>
                                                    {
                                                        (currentWorkflow.status === 1 || authStore.user.roles[0] === 'Mechanic' || authStore.user.roles[0] === 'Municipality' || authStore.user.roles[0] === 'Manager') && currentWorkflow.comments && 
                                                        <div className="bg-light" style={{padding: '30px'}}>
                                                            <h3>Σχόλια</h3>
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Χρήστης</th>
                                                                        <th>Ημερομηνία / Ώρα</th>
                                                                        <th>Σχόλιο</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        JSON.parse(currentWorkflow.comments).sort((a: any, b: any) => a.Dt < b.Dt ? 1 : -1).map((c: any) => {
                                                                            console.log(c)
                                                                            if(c.Comment){
                                                                                return <tr key={c.dt}>
                                                                                    <td>{c.UserName}</td>
                                                                                    <td>{c.Dt.split('T')[0].split('-').reverse().join('-')} {c.Dt.split('T')[1].substr(0, 5)}</td>
                                                                                    <td>{c.Comment}</td>
                                                                                </tr>
                                                                            }
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    }
                                                    {
                                                        step.actions.filter((a: IWorkflowStepAction) => a.type === 'assignToMechanic').length > 0  && currentWorkflow?.actions?.filter((a: IWorkflowInstanceStateAction) => {
                                                            for(let i = 0; i < (workflow?.steps ? workflow?.steps.length : 0); i ++){
                                                                let st = workflow?.steps[i];
                                                                if(a.status === st?.status && st.actions.filter((aa: IWorkflowStepAction) => {
                                                                    return aa.type === 'mechanicActions'
                                                                }).length > 0){
                                                                    return true
                                                                }
                                                                else return false;
                                                            }
                                                        }).length > 0 && <h4 className="text-danger text-center">
                                                            {
                                                                currentWorkflow?.actions?.filter((a: IWorkflowInstanceStateAction) => {
                                                                    for(let i = 0; i < (workflow?.steps ? workflow?.steps.length : 0); i ++){
                                                                        let st = workflow?.steps[i];
                                                                        if(a.status === st?.status && st.actions.filter((aa: IWorkflowStepAction) => {
                                                                            return aa.type === 'mechanicActions'
                                                                        }).length > 0){
                                                                            return true
                                                                        }
                                                                        else return false;
                                                                    }
                                                                })[0].data
                                                            }
                                                        </h4>
                                                    }
                                                    {
                                                        step.actions
                                                        ?.filter((ac: IWorkflowStepAction) => {
                                                            return ac.runTime === 'during'
                                                        })
                                                        .sort((a: IWorkflowStepAction, b: IWorkflowStepAction) => a.order > b.order ? 1 : -1)
                                                        .map((ac: IWorkflowStepAction) => {
                                                            if(ac.type === 'completeField'){
                                                                let cur_value = currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0] ? currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0].data : '';
                                                                return <Fragment>
                                                                    <h4>{ac.name}</h4>
                                                                    <p>{ac.description}</p>
                                                                    <FormGroup>
                                                                        <Label>{ac.target} { ac.required ? "*" : '' }</Label>
                                                                        <Input type="textarea" name={ac.id} defaultValue={cur_value} onChange={(e: FormEvent<HTMLInputElement>) => { changeTextInput(e.currentTarget.value, ac.id) }}/>
                                                                    </FormGroup>
                                                                    <hr />
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'uploadFile'){
                                                                return <Fragment key={ac.id}>
                                                                    <h4>{ac.name}</h4>
                                                                    <p>{ac.description}</p>
                                                                    <FormGroup>
                                                                        <Label>{ac.target} { ac.required ? "*" : '' }</Label>
                                                                        <Input onChange={(e: FormEvent<HTMLInputElement>) => changeFileInput(e, ac.id)} type="file" className="form-control" name={ac.id}  />
                                                                    </FormGroup>
                                                                    {
                                                                        currentWorkflow?.actions
                                                                        .filter((a) => a.actionId === ac.id).map((a: IWorkflowInstanceStateAction) => {
                                                                            let files = JSON.parse(a.data);
                                                                            console.log(files);
                                                                            //console.log(file);
                                                                            if(files && files.length){
                                                                                return (
                                                                                    <Table className="table-striped">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Ημερομηνία - Ώρα</th>
                                                                                                <th>Αρχείο</th>
                                                                                                <th>Ενέργειες</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                files.map((file:any, index: number) => {
                                                                                                    return <tr>
                                                                                                        <td>
                                                                                                            {file.mtime ? (file.mtime.split('T')[0].split('-').reverse().join('-') + ' ' + file.mtime.split('T')[1].substr(0, 5)) : ''} 
                                                                                                        </td>
                                                                                                        <td>
                                                                                                        <a className="btn btn-info" href={encodeURI("https://filemanager.intelliflow.gr/uploads/" + file.name)} download={file.name} target="_blank">{file.name.substring(14)}</a>
                                                                                                        </td>
                                                                                                            
                                                                                                        <td>
                                                                                                            {
                                                                                                                step.status === currentWorkflow?.status && 
                                                                                                                <Button color="danger" onClick={
                                                                                                                    () => {
                                                                                                                        deleteFile(a, index);
                                                                                                                    }
                                                                                                                }>Διαγραφή</Button>
                                                                                                            }
                                                                                                            
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </Table>)
                                                                                
                                                                            }
                                                                        })
                                                                    }
                                                                    <hr />
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'selectAnOption'){
                                                                let cur_value = currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0] ? currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0].data : '';
                                                                console.log(cur_value)
                                                                return <Fragment key={ac.id}>
                                                                    <p >{ac.description}</p>
                                                                    <FormGroup>
                                                                        <Label style={{fontWeight: 'bold'}}>{ac.name} { ac.required ? "*" : '' }</Label>
                                                                        <Input type="select" value={cur_value} name={ac.id} onChange={(e: FormEvent<HTMLInputElement>) => { console.log(ac.target);changeTextInput(e.currentTarget.value, ac.id) }}>
                                                                            <option value="">Επιλέξτε ένα από τα ακόλουθα</option>
                                                                            {
                                                                                ac.target.split('|').map((op: string) => {
                                                                                    console.log(op);
                                                                                    return <option key={v4()} value={op}>{op}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </FormGroup>
                                                                    <hr />
                                                                    
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'selectMultipleOptions'){
                                                                return <Fragment key={ac.id}>
                                                                    <p>{ac.description}</p>
                                                                    <FormGroup>
                                                                        <Label>{ac.name} { ac.required ? "*" : '' }</Label>
                                                                        <Input type="select" name={ac.id} multiple>
                                                                            {
                                                                                ac.target.split('|').map((op: string) => {
                                                                                    return <option>{op}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </FormGroup>
                                                                    <hr />
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'assignToMechanic'){
                                                                let cur_value = currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0] ? currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0].data : '';
                                                                console.log(cur_value);
                                                                return <Fragment key={ac.id}>
                                                                    <p>{ac.description}</p>
                                                                    <FormGroup>
                                                                        <Label>{ac.name} { ac.required ? "*" : '' } (επιλέξτε πρώτα τον βασικό αξιολογητή και μετά τους υπόλοιπους)</Label>
                                                                        <Input type="select" onChange={
                                                                            (e: ChangeEvent<HTMLInputElement>) => {
                                                                                changeTextInput(e.currentTarget.value, ac.id);
                                                                            }
                                                                        }>
                                                                            <option value="">Επιλέξτε τον βασικό Αξιολογητή</option>
                                                                            {
                                                                                availableUsers.map(u => {
                                                                                return <option value={u.email}>{u.displayName}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </FormGroup>
                                                                    <hr />
                                                                    
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'assignToMechanics'){
                                                                let cur_value = currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0] ? currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0].data : '';
                                                                console.log(cur_value);
                                                                return <Fragment key={ac.id}>
                                                                    <p>{ac.description}</p>
                                                                    <FormGroup>
                                                                        <Label>{ac.name} { ac.required ? "*" : '' } (επιλέξτε πρώτα τον βασικό αξιολογητή και μετά τους υπόλοιπους)</Label>
                                                                        <Select multi values={[...availableUsers.filter(u => cur_value.includes(u.email))
                                                                                                .map( u => {
                                                                                                    return {label: u.displayName, value: u.email}
                                                                                                })]} 
                                                                                options={[{label: 'Επιλέξτε Αξιολογητές', value: ''}, ...availableUsers.map(u => {
                                                                                    return {label: u.displayName, value: u.email}
                                                                                })]}
                                                                                onChange={(values) => {
                                                                                    changeSelectInput(values.reduce((sa: string[], v) => {
                                                                                        sa.push(v.value);
                                                                                        return sa
                                                                                    }, []).join(','), ac.id);
                                                                                }} />
                                                                    </FormGroup>
                                                                    <hr />
                                                                    
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'mechanicAction'){
                                                                let cur_value = currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0] ? currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0].data : '';
                                                                console.log(cur_value);
                                                                return <Fragment key={ac.id}>
                                                                    <p>{ac.description}</p>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col md="12">
                                                                            <Label>Αιτιολόγηση</Label>
                                                                            <Input type="textarea" defaultValue={cur_value} onChange={(e: FormEvent<HTMLInputElement>) => {changeTextInput(e.currentTarget.value, ac.id)}}></Input>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <Button block color="danger" onClick={() => { if(cur_value) { sendBackToManagerInstance();} else {seterrormessage('Πρέπει να εισάγετε αιτιολόγηση'); setTimeout(() => {seterrormessage('')}, 3000)}}}>Απόρριψη</Button>
                                                                        </Col>
                                                                        
                                                                    </Row>
                                                                    
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'checkFields'){
                                                                let cur_value = currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0] ? currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0].data : '';
                                                                return <Fragment key={ac.id}>
                                                                    <p>{ac.description}</p>
                                                                    <Row>
                                                                        <Col md="9">
                                                                            <Table className="table table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Πεδίο</th>
                                                                                        <th>Τιμή</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                {
                                                                                    currentWorkflow?.actions
                                                                                    .filter((a: IWorkflowInstanceStateAction) => a.status === parseInt(ac.target))
                                                                                    .sort((a: IWorkflowInstanceStateAction, b: IWorkflowInstanceStateAction) => (a.action ? a.action.order : 0) < (b.action ? b.action?.order : 0) ? -1 : 1)
                                                                                    .map((a: IWorkflowInstanceStateAction) => {
                                                                                        if(a.action?.type === 'uploadFile'){
                                                                                            let files = JSON.parse(a.data);
                                                                                            return <tr>
                                                                                                <td>{a.action.name}</td>
                                                                                                <td>
                                                                                                    <Table className="table-striped">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>Ημερομηνία - Ώρα</th>
                                                                                                                <th>Αρχείο</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                files.map((file:any) => {
                                                                                                                    return <tr>
                                                                                                                        <td>
                                                                                                                            {file.mtime ? (file.mtime.split('T')[0].split('-').reverse().join('-') + ' ' + file.mtime.split('T')[1].substr(0, 5)) : ''} 
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                        <a className="btn btn-info" href={"https://filemanager.intelliflow.gr/uploads/" + file.name} download={file.name} target="_blank">{file.name.substring(14)}</a>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                })
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </Table>
                                                                                            </td>
                                                                                            </tr>
                                                                                        }
                                                                                        if(a.action?.type === 'completeField'){
                                                                                            return <tr>
                                                                                                <td>{a.action.name}</td>
                                                                                                <td>{a.data}</td>
                                                                                            </tr>
                                                                                        }
                                                                                        if(a.action?.type === 'selectAnOption'){
                                                                                            return <tr>
                                                                                                <td>{a.action.name}</td>
                                                                                                <td>{a.data}</td>
                                                                                            </tr>
                                                                                        }
                                                                                        if(a.action?.type === 'selectMultipleOptions'){
                                                                                            return <tr>
                                                                                                <td>{a.action.name}</td>
                                                                                                <td>{a.data}</td>
                                                                                            </tr>
                                                                                        }
                                                                                    })
                                                                                }
                                                                                </tbody>
                                                                            </Table>
                                                                            <hr />
                                                                            <Row>
                                                                                <Col md="12">
                                                                                    <Label>Αιτιολόγηση</Label>
                                                                                    <Input type="textarea" defaultValue={cur_value} onChange={(e: FormEvent<HTMLInputElement>) => {changeComment(e); changeTextInput(e.currentTarget.value, ac.id)}}></Input>
                                                                                    <hr />
                                                                                </Col>
                                                                                <Col md="6">
                                                                                    <Button block color="danger" onClick={() => {if(cur_value) { rejectInstance();} else {seterrormessage('Πρέπει να εισάγετε αιτιολόγηση'); setTimeout(() => {seterrormessage('')}, 3000)}}}>Απόρριψη</Button>
                                                                                </Col>
                                                                                <Col md="6" className="text-center">
                                                                                    <Button block color="warning" onClick={() => { if(cur_value) { sendBackInstance(parseInt(ac.target));} else {seterrormessage('Πρέπει να εισάγετε αιτιολόγηση'); setTimeout(() => {seterrormessage('')}, 3000)} }}>Επιστροφή για διορθώσεις</Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md="3">
                                                                            {
                                                                                currentWorkflow?.instanceComments.map((ic) => {
                                                                                    return <Row>
                                                                                        <Col md="3">
                                                                                            {ic.user?.email}
                                                                                        </Col>
                                                                                        <Col md="6">
                                                                                            {ic.text}
                                                                                        </Col>
                                                                                        <Col md="3">
                                                                                            {ic.approved ? <span className="text-success">Εγκρίνει</span> : <span className="text-danger">Απορρίπτει</span>}
                                                                                        </Col>
                                                                                    </Row>
                                                                                })
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Fragment>
                                                            }
                                                        })
                                                    }
                                                </CardBody>
                                                
                                                <CardFooter>
                                                    <Button color="primary" className="float-right" style={{marginRight: '15px'}} onClick={nextStep}>{
                                                        step.actions.filter((a: IWorkflowStepAction) => a.type === 'mechanicAction').length > 0 ? 'Ανάληψη' : 
                                                        (step.actions.filter((a: IWorkflowStepAction) => a.type === 'checkFields').length > 0 ? 'Ένταξη' : 'Υποβολή')
                                                    }</Button>
                                                    <Link to="/myworkflows" className="btn btn-warning float-right" style={{marginRight: '15px'}}>Πίσω στις προτάσεις</Link>
                                                    <Button color="success" className="float-right" style={{marginRight: '15px'}} onClick={kanetomalaka}>Προσωρινή αποθήκευση</Button>
                                                </CardFooter>
                                            </Fragment>
                                        )
                                    }
                                    {
                                        currentWorkflow && 
                                        (step.status < currentWorkflow?.status || (currentWorkflow?.completed === true || currentWorkflow.paused === true)) &&
                                        currentviewing === step.status && 
                                        (!authStore.user?.roles.includes('Municipality') || step.role === 'Municipality')  && (
                                            <Fragment>
                                                <CardBody>
                                                    {
                                                        step.actions
                                                        ?.filter((ac: IWorkflowStepAction) => {
                                                            return ac.runTime === 'during'
                                                        })
                                                        .sort((a: IWorkflowStepAction, b: IWorkflowStepAction) => a.order > b.order ? 1 : -1)
                                                        .map((ac: IWorkflowStepAction) => {
                                                            if(ac.type === 'completeField'){
                                                                let cur_value = currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0] ? currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0].data : '';
                                                                return <Fragment>
                                                                    <h4>{ac.name}</h4>
                                                                    <p>{ac.description}</p>
                                                                    <FormGroup>
                                                                        <Label>{ac.target}</Label>
                                                                        <h4>{cur_value}</h4>
                                                                    </FormGroup>
                                                                    <hr />
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'uploadFile'){
                                                                return <Fragment key={ac.id}>
                                                                    <h4>{ac.name}</h4>
                                                                    <p>{ac.description}</p>
                                                                    <FormGroup>
                                                                        <Label>{ac.target}</Label>
                                                                        
                                                                    </FormGroup>
                                                                    {
                                                                        currentWorkflow?.actions
                                                                        .filter((a) => a.actionId === ac.id).map((a: IWorkflowInstanceStateAction) => {
                                                                            let files = JSON.parse(a.data);
                                                                            console.log(files);
                                                                            if(files && files.length){
                                                                                return (
                                                                                    <Table className="table-striped">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Ημερομηνία - Ώρα</th>
                                                                                                <th>Αρχείο</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                files.map((file:any) => {
                                                                                                    return <tr>
                                                                                                        <td>
                                                                                                            {file.mtime ? (file.mtime.split('T')[0].split('-').reverse().join('-') + ' ' + file.mtime.split('T')[1].substr(0, 5)) : ''} 
                                                                                                        </td>
                                                                                                        <td>
                                                                                                        <a className="btn btn-info" href={encodeURI("https://filemanager.intelliflow.gr/uploads/" + file.name)} download={file.name} target="_blank">{file.name.substring(14)}</a>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </Table>)
                                                                                
                                                                            }
                                                                        })
                                                                    }
                                                                    <hr />
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'selectAnOption'){
                                                                var cur_value = currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0].data;
                                                                return <Fragment key={ac.id}>
                                                                    <p >{ac.description}</p>
                                                                    <FormGroup>
                                                                        <Label style={{fontWeight: 'bold'}}>{ac.name}</Label>
                                                                        <Input type="select" disabled name={ac.id} value={cur_value} onChange={(e: FormEvent<HTMLInputElement>) => { console.log(ac.target);changeTextInput(e.currentTarget.value, ac.id) }}>
                                                                            <option value="">Επιλέξτε ένα από τα παρακάτω</option>
                                                                            {
                                                                                ac.target.split('|').map((op: string) => {
                                                                                    return <option key={v4()}  value={op}>{op}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </FormGroup>
                                                                    <hr />
                                                                    
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'selectMultipleOptions'){
                                                                return <Fragment key={ac.id}>
                                                                    <p>{ac.description}</p>
                                                                    <FormGroup>
                                                                        <Label>{ac.name}</Label>
                                                                        <Input type="select" name={ac.id} multiple>
                                                                            {
                                                                                ac.target.split('|').map((op: string) => {
                                                                                    return <option>{op}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </FormGroup>
                                                                    <hr />
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'assignToMechanic'){
                                                                let cur_value = currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0] ? currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0].data : '';
                                                                console.log(cur_value);
                                                                return <Fragment key={ac.id}>
                                                                    <p>{ac.description}</p>
                                                                    <FormGroup>
                                                                        <Label>{ac.name}</Label>
                                                                        <Input type="select" onChange={
                                                                            (e: ChangeEvent<HTMLInputElement>) => {
                                                                                changeTextInput(e.currentTarget.value, ac.id);
                                                                            }
                                                                        }>
                                                                            <option value="">Επιλέξτε τον βασικό Αξιολογητή</option>
                                                                            {
                                                                                availableUsers.map(u => {
                                                                                return <option value={u.email}>{u.displayName}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </FormGroup>
                                                                    <hr />
                                                                    
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'assignToMechanics'){
                                                                let cur_value = currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0] ? currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0].data : '';
                                                                console.log(cur_value);
                                                                return <Fragment key={ac.id}>
                                                                    <p>{ac.description}</p>
                                                                    <FormGroup>
                                                                        <Label>{ac.name} (επιλέξτε πρώτα τον βασικό αξιολογητή και μετά τους υπόλοιπους)</Label>
                                                                        <Select multi values={[...availableUsers.filter(u => cur_value.includes(u.email))
                                                                                                .map( u => {
                                                                                                    return {label: u.displayName, value: u.email}
                                                                                                })]} 
                                                                                options={[{label: 'Επιλέξτε Αξιολογητές', value: ''}, ...availableUsers.map(u => {
                                                                                    return {label: u.displayName, value: u.email}
                                                                                })]}
                                                                                onChange={(values) => {
                                                                                    changeSelectInput(values.reduce((sa: string[], v) => {
                                                                                        sa.push(v.value);
                                                                                        return sa
                                                                                    }, []).join(','), ac.id);
                                                                                }} />
                                                                    </FormGroup>
                                                                    <hr />
                                                                    
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'mechanicAction'){
                                                                let cur_value = currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0] ? currentWorkflow?.actions
                                                                .filter((a) => a.actionId === ac.id)[0].data : '';
                                                                console.log(cur_value);
                                                                return <Fragment key={ac.id}>
                                                                    <p>{ac.description}</p>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col md="12">
                                                                            <Label>Αιτιολόγηση</Label>
                                                                            <Input disabled type="textarea" defaultValue={cur_value} onChange={(e: FormEvent<HTMLInputElement>) => {changeTextInput(e.currentTarget.value, ac.id)}}></Input>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Fragment>
                                                            }
                                                            if(ac.type === 'checkFields'){
                                                                if(currentWorkflow?.userAssigned?.email === authStore?.user?.email || authStore?.user?.roles[0] === "Administrator"){
                                                                    let cur_value = currentWorkflow?.actions
                                                                    .filter((a) => a.actionId === ac.id)[0] ? currentWorkflow?.actions
                                                                    .filter((a) => a.actionId === ac.id)[0].data : '';
                                                                    return <Fragment key={ac.id}>
                                                                        <p>{ac.description}</p>
                                                                        <Table className="table table-striped">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Πεδίο</th>
                                                                                    <th>Τιμή</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {
                                                                                currentWorkflow?.actions
                                                                                .filter((a: IWorkflowInstanceStateAction) => a.status === parseInt(ac.target))
                                                                                .map((a: IWorkflowInstanceStateAction) => {
                                                                                    if(a.action?.type === 'uploadFile'){
                                                                                        let file = JSON.parse(a.data)[0];
                                                                                        return <tr>
                                                                                            <td>{a.action.name}</td>
                                                                                            <td><a className="btn btn-info" href={"https://filemanager.intelliflow.gr/uploads/" + file.name} download={file.name} target="_blank">{file.name.substring(14)}</a></td>
                                                                                        </tr>
                                                                                    }
                                                                                    if(a.action?.type === 'completeField'){
                                                                                        return <tr>
                                                                                            <td>{a.action.name}</td>
                                                                                            <td>{a.data}</td>
                                                                                        </tr>
                                                                                    }
                                                                                })
                                                                            }
                                                                            </tbody>
                                                                        </Table>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <Label>Αιτιολόγηση</Label>
                                                                                <Input type="textarea" readOnly defaultValue={cur_value} onChange={(e: FormEvent<HTMLInputElement>) => {changeTextInput(e.currentTarget.value, ac.id)}}></Input>
                                                                            </Col>
                                                                            
                                                                        </Row>
                                                                    </Fragment>
                                                                }
                                                                if(authStore.user?.roles[0] === "Mechanic" && currentWorkflow?.otherUsers?.includes(authStore.user?.email)){
                                                                    return <Fragment>
                                                                        <h3>Δεδομένα Υποβολής</h3>
                                                                        <Table className="table table-striped">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Πεδίο</th>
                                                                                    <th>Τιμή</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {
                                                                                currentWorkflow?.actions
                                                                                .filter((a: IWorkflowInstanceStateAction) => a.status === parseInt(ac.target))
                                                                                .map((a: IWorkflowInstanceStateAction) => {
                                                                                    if(a.action?.type === 'uploadFile'){
                                                                                        let file = JSON.parse(a.data)[0];
                                                                                        return <tr>
                                                                                            <td>{a.action.name}</td>
                                                                                            <td><a className="btn btn-info" href={"https://filemanager.intelliflow.gr/uploads/" + file.name} download={file.name} target="_blank">{file.name.substring(14)}</a></td>
                                                                                        </tr>
                                                                                    }
                                                                                    if(a.action?.type === 'completeField'){
                                                                                        return <tr>
                                                                                            <td>{a.action.name}</td>
                                                                                            <td>{a.data}</td>
                                                                                        </tr>
                                                                                    }
                                                                                })
                                                                            }
                                                                            </tbody>
                                                                        </Table>
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <Input type="textarea" onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                                    setNewComment({
                                                                                        ...newComment, 
                                                                                        text: e.currentTarget.value
                                                                                    })
                                                                                }}></Input>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md="6">
                                                                                <Button color="danger" onClick={() => { saveComment(false) }}>Απόρριψη</Button>
                                                                            </Col>
                                                                            <Col md="6">
                                                                                <Button color="success" onClick={() => { saveComment(true) }}>Έγκριση</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Fragment>
                                                                }
                                                            }
                                                        })
                                                    }
                                                </CardBody>
                                                
                                            </Fragment>
                                        )
                                    }
                                </Card>
                            </Col>
                        )
                    })
                    
                }
                
            </Row>
            
        </Fragment>
    )
};

export default observer(MyWorkflowComponent);
